//
//	Main.jsx
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext, useSocketContext } from '../context';
// import Header from '../components/Header';
import Logo from '../components/Logo';
import { Buttons, Button } from '../components/Buttons';


export const Main = () => {
  
  const socket = useSocketContext();
  const {
    WebApp,
    isLocalhost,
    isConnected, setIsConnected,
    user, setUser } = useAppContext();
  
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    // console.log('Current location is ', location)
    let BackButton = WebApp.BackButton;BackButton.hide();
  }, [location])
  
  // useEffect(() => {
  //   let BackButton = WebApp.BackButton;
  //   BackButton.show();
  //   BackButton.onClick(() => {
  //     BackButton.hide();
  //   });
  //   WebApp.onEvent('backButtonClicked', () => {
  //     navigate('/');
  //   });
  // }, [location])
  
  return (
    <>
      <Logo/>
      <Buttons>
        <Button name="Play" to="/play" onClick={() => navigate('/play')}/>
        <Button name="New Game" to="/newgame" onClick={() => navigate('/newgame')}/>
        <Button name="Find Game" to="/findgame" onClick={() => navigate('/findgame')}/>
        <Button name="Tournaments" to="/tournaments" onClick={() => navigate('/tournaments')}/>
        <Button name="Rating" to="/rating" onClick={() => navigate('/rating')}/>
        <Button name="Settings" to="/settings" onClick={() => navigate('/settings')}/>
        <Button name="Info" to="/info" onClick={() => navigate('/info')}/>
        <Button name="Game*" to="/game" onClick={() => navigate('/game')}/>
      </Buttons>
      <BottomText/>
      <BottomVersion number="0.0.13"/>
    </>
  );
}


const BottomText = () => (
  <div className="max260 mxa mt7 mx4 ac fs14">
    The online version of everyone's favorite game.
  </div>
)


const BottomVersion = (props) => {
  const { user } = useAppContext();
  
  const [showUserData, setShowUserData] = useState(false);
  
  return (
    <div className="max260 mxa mt4 mx4 pb4 ac fs14 op5">
      Version: {props.number}
      <hr/>
      <strong onClick={() => setShowUserData(!showUserData)}>User data</strong>
      {showUserData ? (
        <div>
          id: {user.id}<br/>
          tgUserId: {user.tgUserId}<br/>
          tgIsPremium: {user.tgIsPremium ? 'true' : 'false'}<br/>
          tgUsername: {user.tgUsername}<br/>
          currentGameId: {user.currentGameId}<br/>
          games: {user.games}<br/>
          win: {user.win}<br/>
          loose: {user.loose}<br/>
          onlineAt: {user.onlineAt}<br/>
          socketId: {user.socketId}
        </div>
      ) : ''}
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </div>
  )
}
