//
//  context.js
//  playrps.ru | Context
//  Created by Andrey Shpigunov at 26.01.2025
//  All right reserved.
//


import React, { createContext, useContext, useState, useMemo } from 'react';
import { io } from 'socket.io-client';

const socket = io('https://api.playrps.ru');
const SocketContext = createContext(socket);
const AppContext = createContext({});

export const AppContextProvider = (props) => {
  
  const [isConnected, setIsConnected] = useState(true);
  const [user, setUser] = useState(false);
  
  const value = useMemo(() => ({
    WebApp: window.Telegram.WebApp,
    isLocalhost: Boolean(window.location.hostname === 'localhost'),
    isConnected: isConnected,
    setIsConnected: setIsConnected,
    user: user,
    setUser: setUser
  }), [isConnected, user])
  
  return (
    <AppContext.Provider value={ value }>
      { props.children }
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) throw new Error("useAppContext must be used within a AppContextProvider");
  return context
}

export const SocketContextProvider = (props) => {
  return (
    <SocketContext.Provider value={ socket }>
      { props.children }
    </SocketContext.Provider>
  )
}

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) throw new Error("useSocketContext must be used within a SocketContextProvider");
  return context
}





// import React, { createContext } from 'react';
// import { io } from 'socket.io-client';
//
// const isLocalhost = Boolean(window.location.hostname === 'localhost');
// const socket = (isLocalhost ? io('http://localhost:3000') : io('https://api.playrps.ru'));
// const SocketContext = createContext(socket);
//
// const SocketProvider = (props) => {
//   return (
//     <SocketContext.Provider value={socket}>
//       {props.children}
//     </SocketContext.Provider>
//   )
// }
//
// export { SocketContext, SocketProvider };
