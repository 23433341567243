//
//  Play.jsx
//  playrps.ru
//  Created by Andrey Shpigunov at 26.01.2025
//  All right reserved.
//


import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAppContext, useSocketContext } from '../context';
import Logo from '../components/Logo';
import Header, { HeaderLine } from '../components/Header';
import { Buttons, Button } from '../components/Buttons';
import { Block } from '../components/Block';
// import Panel from '../components/Panel';
import rock from '../assets/rock.png';
import paper from '../assets/paper.png';
import scissors from '../assets/scissors.png';





export const Play = () => {
  
  const socket = useSocketContext();
  const {
    WebApp,
    isLocalhost,
    isConnected, setIsConnected,
    user, setUser } = useAppContext();
  
  const [messages, setMessages] = useState([]);
  const [showPanel, setShowPanel] = useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();
  
  const addMessage = (text) => {
    setMessages(oldMessages => [...oldMessages, text]);
  }
  
  
  
  useEffect(() => {
    if (!isConnected) navigate('/')
  }, [isConnected])
  
  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.onEvent('backButtonClicked', () => {
      navigate('/');
    });
    
    socket.emit('server.play');
    
    socket.on('app.play.message', (message) => {
      addMessage(message);
    });
    
    socket.once('app.play.start', (game) => {
      setShowPanel(true);
      socket.emit('server.play.start');
    })
    
    socket.once('app.play.leave', (game) => {
      // addMessage('Opponent leave game');
      // addMessage('Game finished');
      setShowPanel(false);
    })
    
    return function() {
      socket.emit('server.play.leave');
    }
  }, [location]);
  
  return (
    <>
      {/* <HeaderLine title="Game"/> */}
      <Logo/>
      <Header text="Play"/>
      <Block>
        <div className="flex aic jcsa mb3 hidden">
          {/* <div className="fs18 fw700" style={{borderBottom: 'solid 3px'}}>Play global</div> */}
          {/* <div className="fs18 pointer op2" onClick={() => {
            WebApp.showAlert('Invites will be available later')
          }}>Invite user</div> */}
        </div>
        <div className="py6 ac">
          {messages.map(m => {
            return <div>{m}</div>
          })}
        </div>
        <Buttons>
          {/* <Button name="Go Back" to="/"/> */}
          {/* <Button name="Create game" onClick={() => navigate('/')}/> */}
        </Buttons>
      </Block>
      { showPanel ? <Panel func={setShowPanel}/> : ''}
      <br/>
      <br/>
      <Buttons>
        {/* <Button name="Go Back" to="/"/> */}
        <Button name="Go Back" onClick={() => navigate('/')}/>
      </Buttons>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </>
  );
}



const Panel = (props) => {
  
  const socket = useSocketContext();
  
  const setShowPanel = props.func;
  
  const moveRock = () => {
    socket.emit('server.play.move.rock');
    setShowPanel(false);
  }
  
  const movePaper = () => {
    socket.emit('server.play.move.paper');
    setShowPanel(false);
  }
  
  const moveScissors = () => {
    socket.emit('server.play.move.scissors');
    setShowPanel(false);
  }
  
  return (
    <div className="flex jcsa max300 mxa my4">
      <a className="button button_icon" onClick={moveRock}>
        <img src={rock} width="100" height="100"/>
      </a>
      <a className="button button_icon" onClick={movePaper}>
        <img src={paper} width="100" height="100"/>
      </a>
      <a className="button button_icon" onClick={moveScissors}>
        <img src={scissors} width="100" height="100"/>
      </a>
    </div>
  )
}
