//
//	App.js
//	playrps.ru
//
//	Created by Andrey Shpigunov on 05.11.2024.
//


import { useEffect, useState, useCallback } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAppContext, useSocketContext } from './context';
import { Main } from './pages/Main';
import { Game } from './pages/Game';
import { Play } from './pages/Play';
import { NewGame } from './pages/NewGame';
import { FindGame } from './pages/FindGame';
import { Tournaments } from './pages/Tournaments';
import { Rating } from './pages/Rating';
import { Settings } from './pages/Settings';
import { Info } from './pages/Info';
import { NotTelegram } from './components/NotTelegram';
import clsx from 'clsx';
import './App.css';


export default function App(props) {
  
  const socket = useSocketContext();
  const {
    WebApp,
    isLocalhost,
    isConnected, setIsConnected,
    user, setUser } = useAppContext();
    
  const initData = WebApp.initData;
  const [isTelegram, setIsTelegram] = useState(initData);
  
  console.log('Started on ' + (isLocalhost ? 'localhost' : 'production'));
  
  const handleAuth = async (userData) => {
    // Authenticating
    console.log('setUser 1', userData);
    setUser(userData);
    setIsTelegram(userData ? true : false);
    setIsConnected(userData ? true : false)
  }
  
  useEffect(() => {
    
    console.log('initData', initData ? true : false);
    console.log('user', user);
    if (initData && !user) {
      socket.once('connect', () => {
        console.log('Connected to ' + (isLocalhost ? 'localhost' : 'production'));
        socket.emit('server.auth', initData);
        socket.once('app.auth', handleAuth);
      });
      
      socket.on('disconnect', () => {
        socket.removeAllListeners();
        console.log('setUser 2', false);
        setUser(false);
        setIsConnected(false);
      })
    }
    
    socket.onAny((event, ...args) => {
      console.log(`-> ${event}`, args)
    })
    
  }, [isConnected])
  
  
  return (
    <>
      {!isTelegram && 0 ? (
        <NotTelegram/>
      ) : (
        <div className="app mxa max500">
          <div className={clsx('loader', !isConnected ? 'active' : '')}>Connecting...</div>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Main/>}/>
              <Route path="game" element={<Game/>}/>
              <Route path="play" element={<Play/>}/>
              <Route path="newgame" element={<NewGame/>}/>
              <Route path="findgame" element={<FindGame/>}/>
              <Route path="tournaments" element={<Tournaments/>}/>
              <Route path="rating" element={<Rating/>}/>
              <Route path="settings" element={<Settings/>}/>
              <Route path="info" element={<Info/>}/>
              {/* default redirect to home page */}
              <Route path="*" element={<Navigate to="/"/>} />
            </Routes>
          </BrowserRouter>
        </div>
      )}
    </>
  )
}
