//
//  Game.jsx
//  playrps.ru
//  Created by Andrey Shpigunov at 27.01.2025
//  All right reserved.
//


import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import Header, { HeaderLine } from '../components/Header';
import { Buttons, Button } from '../components/Buttons';
import { Block } from '../components/Block';
import Panel from '../components/Panel';


export const Game = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const webApp = window.Telegram.WebApp;
  
  useEffect(() => {
    webApp.BackButton.show();
    webApp.onEvent('backButtonClicked', () => {
      navigate('/');
    });
    // socket.emit('play')
  }, [location])
  
  
  // socket.on('ok-play', (data) => {
    // console.log('Ok, play!', data)
  // })
  
  
  return (
    <>
      {/* <HeaderLine title="Game"/> */}
      <Logo/>
      <Header text="Game"/>
      <Block>
        <div className="flex aic jcsa mb3 hidden">
          {/* <div className="fs18 fw700" style={{borderBottom: 'solid 3px'}}>Play global</div> */}
          {/* <div className="fs18 pointer op2" onClick={() => {
            webApp.showAlert('Invites will be available later')
          }}>Invite user</div> */}
        </div>
        <div className="py6 ac">Search players...</div>
        
        
        
        <Buttons>
          {/* <Button name="Go Back" to="/"/> */}
          {/* <Button name="Create game" onClick={() => navigate('/')}/> */}
        </Buttons>
      </Block>
      <Panel/>
      <br/>
      <br/>
      <Buttons>
        {/* <Button name="Go Back" to="/"/> */}
        <Button name="Go Back" onClick={() => navigate('/')}/>
      </Buttons>
    </>
  );
}
